/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { graphql } from 'gatsby';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';

import SEO from '../components/seo';
import ImageRow from '../components/image-row';
import PageContainer from '../components/page-container';
import Title from '../components/title';
import AddressCard from '../components/address-card';
import Content from '../components/content';
import SubTitle from '../components/subtitle';

const BrandColumn = props => {
  const { brands, heading } = props;
  return (
    <div css={tw`pb-8`}>
      <SubTitle text={heading} />
      <ul css={tw`list-reset`}>
        {brands.map((brand, k) => (
          <li key={k} css={tw`leading-normal`}>
            {brand}
          </li>
        ))}
      </ul>
    </div>
  );
};

BrandColumn.propTypes = {
  heading: PropTypes.string,
  brands: PropTypes.array,
};

const OurShopPage = ({ data }) => {
  const { edges: images } = data.shopImages;
  const { frontmatter: fm, html: content } = data.page;

  return (
    <PageContainer>
      <SEO title={fm.title} description={fm.metaDescription} />
      <div className="section">
        <Title text={fm.title} />
        <ImageRow images={images} />
        <div className="columns">
          <div className="column is-two-thirds">
            <Content html={content} />
          </div>
          <div className="column">
            <div css={tw`pt-4 mb-8`}>
              <img
                src={fm.googleMapUrl}
                css={tw`rounded`}
                alt="Google map of The San Juan Angler in downtown Durango, CO"
              />
            </div>
            <div>
              <AddressCard />
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <Title text="Our Brands" />
        <div css={tw`flex flex-wrap justify-between`}>
          <BrandColumn brands={fm.brands.flies} heading="Flies" />
          <BrandColumn brands={fm.brands.rods} heading="Rods" />
          <BrandColumn brands={fm.brands.reels} heading="Reels" />
          <BrandColumn brands={fm.brands.waders} heading="Boots/Waders" />
          <BrandColumn brands={fm.brands.lines} heading="Lines" />
          <BrandColumn brands={fm.brands.tying} heading="Tying" />
          <BrandColumn brands={fm.brands.apparel} heading="Apparel" />
          <BrandColumn brands={fm.brands.other} heading="Other" />
        </div>
      </div>
    </PageContainer>
  );
};

OurShopPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        metaDescription: PropTypes.string,
        googleMapUrl: PropTypes.string,
      }),
    }),
  }),
};

export default OurShopPage;

export const query = graphql`
  query OurShopPageQuery {
    page: markdownRemark(frontmatter: { title: { eq: "Our Shop" } }) {
      html
      frontmatter {
        title
        metaDescription
        googleMapUrl
        brands {
          flies
          rods
          reels
          waders
          lines
          tying
          apparel
          other
        }
      }
    }
    shopImages: allFile(
      limit: 3
      filter: { relativePath: { regex: "images/our-shop/.*.jpg/" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;
